import { TableFooter } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { taxFilterAtom } from '../../atoms/atoms';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import TableCellNumber from '../../components/TableCellNumber';
import useGiftsListContext from '../../dataContexts/useGiftsListContext';
import { SponsorGift } from '../../models/SponsorGift';
import { TaxFilterModel } from '../../models/TaxFilterModel';
import { hasPublicId, includeInRf1301, includeSponsor } from '../utils/sponsor-utils';
import { isNullOrWhiteSpace } from '../utils/stringUtils';
import TaxSettlementActions from './TaxSettlementActions';
import TaxSettlementFilter from './TaxSettlementFilter';
import TaxSettlementRow from './TaxSettlementRow';

function filterByText(sponsors: SponsorGift[], filter: TaxFilterModel) {
    if (filter.text !== '') {
        const regexp = new RegExp(filter.text, 'i');
        return sponsors.filter(s => includeSponsor(s.sponsor, regexp));
    }

    return sponsors;
}

function filterByMissingSosialSecurityNumner(sponsors: SponsorGift[], filter: TaxFilterModel) {
    if (filter.withMissingSosialSecurityNumber) {
        return sponsors.filter(s => !hasPublicId(s.sponsor));
    }

    return sponsors;
}

function filterByMissingEmail(sponsors: SponsorGift[], filter: TaxFilterModel) {
    if (filter.withMissingEmail) {
        return sponsors.filter(s => isNullOrWhiteSpace(s.sponsor.email1));
    }

    return sponsors;
}

function filterByWillBeReported(sponsors: SponsorGift[], filter: TaxFilterModel) {
    if (filter.onlyWillBeReported) {
        return sponsors.filter(s => includeInRf1301(s));
    }

    return sponsors;
}

function filterByWithAmount(sponsors: SponsorGift[], filter: TaxFilterModel) {
    if (filter.onlyWithAmount) {
        return sponsors.filter(s => s.sum >= 500);
    }

    return sponsors;
}

function filterByLunchPartner(sponsors: SponsorGift[], filter: TaxFilterModel) {
    if (filter.lunchPartner) {
        return sponsors.filter(s => s.isLunchPartner);
    }

    return sponsors;
}

function filterSponsors(sponsors: SponsorGift[], filter: TaxFilterModel) {
    if (sponsors?.length > 0) {
        let result = filterByMissingSosialSecurityNumner(sponsors, filter);
        result = filterByMissingEmail(result, filter);
        result = filterByWillBeReported(result, filter);
        result = filterByWithAmount(result, filter);
        result = filterByLunchPartner(result, filter);
        result = filterByText(result, filter);
        return result;
    }

    return [];
}

export default function TaxSettlementList() {
    const { sponsorsGifts: sponsorsGiftsAll, isLoading, error } = useGiftsListContext(2024);
    const [filter] = useRecoilState(taxFilterAtom);
    const sponsorsGifts = useMemo(() => filterSponsors(sponsorsGiftsAll, filter), [sponsorsGiftsAll, filter]);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Error error={error} />;
    }

    const sums = {
        gift: sponsorsGifts.filter(g => g.gift != null).reduce((p, c) => p + c.gift, 0),
        lunch: sponsorsGifts.filter(g => g.lunch != null).reduce((p, c) => p + c.lunch, 0),
        loss: sponsorsGifts.filter(g => g.loss != null).reduce((p, c) => p + c.loss, 0),
        adjust: sponsorsGifts.filter(g => g.adjust != null).reduce((p, c) => p + c.adjust, 0),
        sum: sponsorsGifts.filter(g => g.sum != null).reduce((p, c) => p + c.sum, 0),
    };

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <TaxSettlementActions sponsorsGifts={sponsorsGifts} />
            </Grid>

            <Grid item>
                <TaxSettlementFilter count={sponsorsGifts.length} />
            </Grid>

            <Grid item>
                <TableContainer component={Paper}>
                    <Table aria-label="Sponsor table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Fadder</TableCell>
                                <TableCell>FNr</TableCell>
                                <TableCell align="right">Gaver</TableCell>
                                <TableCell align="right">Lunch</TableCell>
                                <TableCell align="right">Tap</TableCell>
                                <TableCell align="right">Adjust</TableCell>
                                <TableCell align="right">Sum</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sponsorsGifts.map(s => (
                                <TaxSettlementRow key={s.sponsor.id} sponsorGift={s} />
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>Sum</TableCell>
                                <TableCell />
                                <TableCellNumber showZero={true} value={sums.gift} />
                                <TableCellNumber showZero={true} value={sums.lunch} />
                                <TableCellNumber showZero={true} value={sums.loss} />
                                <TableCellNumber showZero={true} value={sums.adjust} />
                                <TableCellNumber showZero={true} value={sums.sum} />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
